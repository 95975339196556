'use client';

import * as Sentry from '@sentry/nextjs';
import Error from 'next/error';
import { useEffect } from 'react';
export default function GlobalError({
  error
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return (
    // eslint-disable-next-line jsx-a11y/html-has-lang
    <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.jsx">
      <body>
        <Error data-sentry-element="Error" data-sentry-source-file="global-error.jsx" />
      </body>
    </html>
  );
}